<template>
  <div class="flex-col space-y-159 page">
    <div class="flex-col">
      <div class="flex-col section_1">
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626007993025293563.png"
          class="image_31"
        />
        <div class="flex-col group_31">
          <Wapheader />
          <div class="flex-col">
            <div data-aos="fade-up" class="swiper-container s1" style="width: 100%;">
              <div class="swiper-wrapper">
                <div class="swiper-slide" style="width: 100%;">
                  <div class="flex-col section_1" style="position: relative">
                    <video
                      id="index_video"
                      src="../../assets/video/pctt.mp4"
                      style="object-fit: cover;"
                      x5-playsinline="true"
                      webkit-playsinline="true"
                      playsinline="true"
                      x5-video-player-type="h5"
                      x5-video-player-fullscreen="true"
                      x5-video-ignore-metadata="true"
                      autoplay=""
                      muted=""
                      loop="" 
                      
                    ></video>
                    <div
                      class="flex-col"
                      style="position: absolute; width: 100%; bottom: 40px;text-align: center;"
                    >
                      <span style="font-size: 28px;color: white;">为热爱发电</span>
                      <span style="font-size: 28px;color: white;">源自未被定义</span>
                      <div class="flex-row" style=" width: 100%; display: flex;justify-content: center;margin-top: 20px;">
                        <div class="flex-col">
                          <span style="font-size: 16px;color: white;" @click="navPath('/newwapbrand')">了解品牌</span>
                          <span style="width:100%;height:2px;background-color:white;padding-top:2px"></span>
                        </div>
                        <div class="flex-col" style="margin-left:25px;">
                          <span style="font-size: 16px;color: white;" @click="navPath('/newwaplx')">联系我们</span>
                          <span style="width:100%;height:2px;background-color:white;padding-top:2px"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" style="width: 100%;">
                  <div style="position: relative;">
                    <img src="../../assets/images/pctt-1.png" style="width: 100%;"/>
                    <div
                      class="flex-col"
                      style="position: absolute; width: 100%; bottom: 40px;text-align: center;"
                    >
                      <span style="font-size: 28px;color: white;">新世代电轻摩</span>
                      <div class="flex-row" style=" width: 100%; display: flex;justify-content: center;margin-top: 20px;">
                        <div class="flex-col">
                          <span style="font-size: 16px;color: white;" @click="navPath('/newwapx')">了解更多</span>
                          <span style="width:100%;height:2px;background-color:white;padding-top:2px"></span>
                        </div>
                        <div class="flex-col" style="margin-left:25px;">
                          <span style="font-size: 16px;color: white;" @click="navPath('/newwapyy')">预约试驾</span>
                          <span style="width:100%;height:2px;background-color:white;padding-top:2px"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
                <div class="swiper-slide" style="width: 100%;">
                  <div style="position: relative;">
                    <img src="../../assets/images/pctt-2.png" style="width: 100%;"/>
                    <div
                      class="flex-col"
                      style="position: absolute; width: 100%; bottom: 40px;text-align: center;"
                    >
                      <span style="font-size: 28px;color: white;">赛道级电摩</span>
                      <div class="flex-row" style=" width: 100%; display: flex;justify-content: center;margin-top: 20px;">
                        <div class="flex-col">
                          <span style="font-size: 16px;color: white;" @click="navPath('/newwaps7')">了解更多</span>
                          <span style="width:100%;height:2px;background-color:white;padding-top:2px"></span>
                        </div>
                        <div class="flex-col" style="margin-left:25px;">
                          <span style="font-size: 16px;color: white;" @click="navPath('/newwapyy')">预约试驾</span>
                          <span style="width:100%;height:2px;background-color:white;padding-top:2px"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" style="width: 100%;">
                  <div style="position: relative;">
                    <img src="../../assets/images/pctt-3.png" style="width: 100%;"/>
                    <div
                      class="flex-col"
                      style="position: absolute; width: 100%; bottom: 40px;text-align: center;"
                    >
                    <div class="flex-row" style=" width: 100%; display: flex;justify-content: center;">
                      <span style="font-size: 28px;color: black;">经典进化</span>
                      <span style="font-size: 28px;color: black;margin-left: 10px;">新生登场</span>
                    </div>
                      <div class="flex-row" style=" width: 100%; display: flex;justify-content: center;margin-top: 20px;">
                        <div class="flex-col">
                          <span style="font-size: 16px;color: black;" @click="navPath('/newwapilike')">了解更多</span>
                          <span style="width:100%;height:2px;background-color:black;padding-top:2px"></span>
                        </div>
                        <div class="flex-col" style="margin-left:25px;">
                          <span style="font-size: 16px;color: black;" @click="navPath('/newwapyy')">预约试驾</span>
                          <span style="width:100%;height:2px;background-color:black;padding-top:2px"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col group_3">
        <div data-aos="fade-up" class="swiper-container s2">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="swiper-slide"
            >
              <img :src="item.img" class="image_6" />
              <div class="flex-col space-y-51 section_3">
                <a :href="item.href">
                  <div class="flex-col space-y-32 group_4" style="width: 100%">
                    <span class="text_1">{{ item.text1 }}</span>
                    <span class="text_1">{{ item.text2 }}</span>
                    <div class="group_5">
                      <span class="text_2">{{ item.text3 }}</span>
                      <br />
                      <span class="text_4">{{ item.text4 }}</span>
                    </div>
                  </div>
                  <div
                    class="flex-row justify-between group_6"
                    style="margin-top: 20px"
                  >
                    <a :href="item.href">
                      <div class="flex-col text-wrapper">
                        <span class="text_5">了解更多</span>
                      </div>
                    </a>
                    <div class="flex-row group_7"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            style="left: 19rem !important; bottom: 8rem !important"
            class="swiper-pagination swiper-pagination2"
          ></div>
        </div>
        <div class="flex-col items-center group_31 section_4">
          <video
            id="index_video"
            class="image_33"
            src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/video/ionex.mp4"
            style="object-fit: cover"
            x5-playsinline="true"
            webkit-playsinline="true"
            playsinline="true"
            x5-video-player-type="h5"
            x5-video-player-fullscreen="true"
            x5-video-ignore-metadata="true"
            autoplay=""
            muted=""
            loop=""
          ></video>
          <div class="flex-col section_5">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005962341588778.png"
              class="image_12"
            />
            <div class="group_8">
              <span class="text_7">
                『Ionex车能网』智能安全出行生态系统
                <br />
              </span>
              <span class="text_8">
                全面开创两轮智能出行新体验
                <br />
              </span>
              <span class="text_9">是全球电动车市场最佳智能化解决方案</span>
            </div>
            <div class="flex-col items-center space-y-10 group_9">
              <span class="text_10" @click="navPath('/newwapcnw')"
                >了解更多</span
              >
              <div class="section_6"><!----></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col space-y-51">
      <div class="flex-col items-center space-y-29">
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016335125113629.png"
          class="image_13"
        />
        <span class="text_11">热爱之心 源自未被定义</span>
      </div>
      <div class="flex-col">
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005961716858274.png"
          class="image_14"
        />
        <div class="group_13">
            <span class="text_12">
              KYMCO打造一系列经典车型，
              <br />
            </span>
            <span class="text_13">
              组成摩托家族矩阵，
              <br />
            </span>
            <span class="text_14">
              成长为一家全球性的powersport集团，
              <br />
            </span>
            <span class="text_15">业务遍及100多个国家。</span>
          </div>
          <div class="flex-col items-start space-y-10 button">
            <span class="text_16" @click="navPath('/newwapbrand')"
              >了解品牌</span
            >
          </div>
        <div class="flex-col section_7">
          <div class="flex-col space-y-29 group_14">
            <div class="group_15">
              <span class="text_17" style="font-family: Albbr">
                KYMCO APP
                <br />
              </span>
              <span class="text_18" style="font-family: Albbr">
                集结实用工具、直觉操作与车辆资讯
                <br />
              </span>
              <span class="text_19" style="font-family: Albbr"
                >移动生活有了APP更加轻松便利</span
              >
            </div>
            <div class="flex-row justify-between">
              <img
                src="../../assets/images/ewm.jpg"
                class="image_15"
              />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005961306981520.png"
                class="image_16"
              />
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          class="swiper-container s1"
          style="padding-bottom: 20px"
        >
          <div class="swiper-wrapper">
            <div v-for="t in newList" class="swiper-slide">
              <a :href="t.href">
                <img :src="t.url" class="image_17" />
                <div class="flex-col space-y-49 section_9">
                  <div class="flex-col items-start" style="width: 325.72px;">
                    <span class="text_20">{{ t.title }}</span>
                    <span class="text_21">{{ t.desc }}</span>
                    <span class="text_22">{{ t.date }}</span>
                  </div>
                  <div class="flex-row justify-between">
                    <div class="flex-col text-wrapper">
                      <span class="text_5">了解更多</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div
            style="left: 19rem !important; bottom: 8rem !important"
            class="swiper-pagination swiper-pagination1"
          ></div>
        </div>
        <Wapfooter />
      </div>
    </div>
  </div>
</template>

<script>
import Wapheader from "../../components/wapheader/wapheader.vue";
import Wapfooter from "../../components/wapfooter/wapfooter.vue";

export default {
  components: { Wapheader, Wapfooter },
  data() {
    return {
      newList: [
        {
          url: "https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016331896785882.png",
          title:"KYMCO光阳电动-豪华产品矩阵闪耀2021北京国际摩展",
          desc: "5月28日，『KYMCO光阳电动』携电摩F9...",
          date: "2021.05.29",
          href: "https://mp.weixin.qq.com/s/Wg03ieOH4koiVmmmyEUNng",
        },
        {
          url: "https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016331918602961.png",
          title: "光阳与速珂, 电斐签署战略协议,顶级制造+酷和好玩+热血新锐",
          desc: "5月20日常州光阳摩托车有限公司举行签约仪式，常州光阳...",
          date: "2021.05.20",
          href: "https://mp.weixin.qq.com/s?__biz=Mzg2Nzc1Mzk2NA==&mid=2247485791&idx=1&sn=0e20ef708127acd8b4c2dc363c242f5c&source=41#wechat_redirect",
        },
        {
          url: "https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16607016332970830791.png",
          title: "KYMCO Ionex 3.0「全面超越」發表會 四款125cc等級...",
          desc: "2021年3月18日，台北訊-全球機車專業品牌光陽工業...",
          date: "2021.05.29",
          href: "https://www.kymco.com.tw/news/766-KYMCO-Ionex-3.0%E3%80%8C%E5%85%A8%E9%9D%A2%E8%B6%85%E8%B6%8A%E3%80%8D%E7%99%BC%E8%A1%A8%E6%9C%83-%E5%9B%9B%E6%AC%BE125cc%E7%AD%89%E7%B4%9A%E9%9B%BB%E5%8B%95%E6%A9%9F%E8%BB%8A%E9%9C%87%E6%92%BC%E7%99%BB%E5%A0%B4",
        },
      ],
      list: [
        {
          href: "https://mp.weixin.qq.com/s/phrfCaxdfg2DqS4xdJ0mxw",
          img: require("../../assets/images/ban1.png"),
          text1: "四城试驾品鉴会",
          text2: "火热预约中",
          text3: "苏州、成都、郑州、西安",
          text4: "多重礼遇 乐享、独享、尽享！",
        },
        {
          href: "https://mp.weixin.qq.com/s/sIuTdiFnTfEBITpNI2NpGw",
          img: require("../../assets/images/ban2.png"),
          text1: "你说耀有光",
          text2: "S7线上发布会精彩全回顾",
          text3: "一张图了解赛道级电摩",
          text4: "",
        },
        {
          href: "https://mp.weixin.qq.com/s/Wg03ieOH4koiVmmmyEUNng",
          img: require("../../assets/images/ban3.png"),
          text1: "KYMCO光阳电动豪华产品矩阵",
          text2: "闪耀2021北京国际摩展",
          text3: "KYMCO引领行业发展的创新实力与雄心壮志",
          text4: "为都市消费者打造多重维度的豪华智能出行体验",
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(".s1", {
        autoplay: {
          delay: 8000,
        },
        speed: 1200,
        allowTouchMove: true,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 3,
        },
        centeredSlides: true,
        loop: true,
        slidesPerView: "auto",
        on: {
          slideChangeTransitionEnd: function () {
            //this.slides.transition(this.params.autoplay.delay+this.params.speed).transform('translate3d(-60px, 0, 0)');
          },
          slideChangeTransitionStart: function () {
            $(".s1 .slick-active").removeClass(
              "swiper-pagination-bullet-actives"
            );
            $(".s1 .slick-active")
              .eq(this.realIndex)
              .addClass("swiper-pagination-bullet-actives");
            // this.slides.transition(this.params.speed).transform('translate3d(0, 0, 0)');
          },
        },
        pagination: {
          el: ".swiper-pagination1",
          clickable: true,
          renderBullet: function (index, className) {
            return `<li role="presentation"  class="slick-active active ${className}"><button type="button" role="tab"
						id="slick-slide-control02" aria-controls="slick-slide02" aria-label="3 of 3" tabindex="0"
						aria-selected="true">3<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"
							preserveAspectRatio="none">
							<circle cx="6" cy="6" r="5" class="background"></circle>
							<circle cx="6" cy="6" r="5" class="annulus"></circle>
						</svg></button></li>`;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      new Swiper(".s2", {
        autoplay: {
          delay: 5000,
        },
        allowTouchMove: true,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 3,
        },
        centeredSlides: true,
        loop: true,
        slidesPerView: "auto",
        on: {
          slideChangeTransitionEnd: function () {
            //this.slides.transition(this.params.autoplay.delay+this.params.speed).transform('translate3d(-60px, 0, 0)');
          },
          slideChangeTransitionStart: function () {
            $(".s2 .slick-active").removeClass(
              "swiper-pagination-bullet-actives"
            );
            $(".s2 .slick-active")
              .eq(this.realIndex)
              .addClass("swiper-pagination-bullet-actives");
            // this.slides.transition(this.params.speed).transform('translate3d(0, 0, 0)');
          },
        },
        pagination: {
          el: ".swiper-pagination2",
          clickable: true,
          renderBullet: function (index, className) {
            return `<li role="presentation"  class="slick-active active ${className}"><button type="button" role="tab"
						id="slick-slide-control02" aria-controls="slick-slide02" aria-label="3 of 3" tabindex="0"
						aria-selected="true">3<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"
							preserveAspectRatio="none">
							<circle cx="6" cy="6" r="5" class="background"></circle>
							<circle cx="6" cy="6" r="5" class="annulus"></circle>
						</svg></button></li>`;
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    });
  },

  methods: {},
};
</script>
<style lang="scss">
.s1 {
  .swiper-pagination-bullet {
    opacity: 1 !important;
  }

  .swiper-pagination-bullet {
    background-color: transparent !important;
  }
}
.s2 {
  .swiper-pagination-bullet {
    opacity: 1 !important;
  }

  .swiper-pagination-bullet {
    background-color: transparent !important;
  }
}
</style>
<style scoped lang="css">
.space-y-159 > *:not(:first-child) {
  margin-top: 159px;
}
.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section_1 {
  padding-bottom: 84px;
  height: 1334px;
  position: relative;
}
.image_31 {
  width: 750px;
  height: 1334.00025px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}
.group_31 {
  position: relative;
}
.image_3 {
  margin-top: 187px;
  align-self: center;
  width: 441px;
  height: 53px;
}
.space-y-35 > *:not(:first-child) {
  margin-top: 35px;
}
.group_1 {
  margin-top: 885px;
  padding-left: 164px;
  padding-right: 154px;
}
.text {
  color: #000;
  font-size: 52px;
  display: block;
  line-height: 49px;
}
.group_2 {
  padding: 0 61px;
}
.image_4 {
  width: 115px;
  height: 41px;
}
.image_5 {
  margin-right: 9px;
  width: 116px;
  height: 41px;
}
.group_3 {
  padding-top: 33px;
}
.image_6 {
  margin: 0 30px;
  width: 690px;
  height: 420.00000000000006px;
}
.space-y-51 > *:not(:first-child) {
  margin-top: 51px;
}
.section_3 {
  margin: 0 30px;
  padding: 57px 48px 72px;
  background-color: #eaeaea;
}
.space-y-32 > *:not(:first-child) {
  margin-top: 32px;
}
.group_4 {
  align-self: flex-start;
  width: 435px;
}
.text_1 {
  color: #000;
  font-size: 36px;
  display: block;
  font-weight: 700;
  line-height: 35px;
}
.group_5 {
  line-height: 36px;
}
.text_2 {
  color: #000;
  font-size: 26px;
  line-height: 36px;
}
.text_3 {
  color: #000;
  font-size: 28px;

  font-weight: 700;
  line-height: 36px;
}
.text_4 {
  color: #000;
  font-size: 26px;

  line-height: 36px;
}
.group_6 {
  padding: 0 2px;
}
.text-wrapper {
  padding-bottom: 10px;
  border-bottom: solid 2px #007bff;
}
.text_5 {
  color: #007aff;
  font-size: 30px;

  line-height: 29px;
}
.group_7 {
  margin-top: 4px;
}
.image_7 {
  width: 20px;
  height: 20px;
}
.image_32 {
  margin: 7px 0 9px;
}
.image_9 {
  margin-left: 37px;
  width: 36px;
  height: 36px;
}
.image_11 {
  margin: 7px 0 9px 36px;
}
.section_4 {
  margin-top: 30px;
}
.image_33 {
  width: 750px;
  height: 999.9997500000001px;
}
.section_5 {
  padding: 244px 125px 310px 127px;
  background-color: #00000059;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}
.image_12 {
  margin-left: 48px;
  margin-right: 50px;
  width: 400px;
  height: 139px;
}
.group_8 {
  margin-top: 67px;
  text-align: center;
}
.text_7 {
  color: #ffffff;
  font-size: 28px;
  display: block;
  line-height: 40px;
}
.text_8 {
  color: #ffffff;
  font-size: 28px;
  display: block;

  line-height: 40px;
}
.text_9 {
  color: #ffffff;
  font-size: 28px;
  display: block;
  line-height: 40px;
}
.space-y-10 > *:not(:first-child) {
  margin-top: 10px;
}
.group_9 {
  margin-top: 78px;
}
.text_10 {
  color: #ffffff;
  font-size: 30px;

  line-height: 29px;
}
.section_6 {
  background-color: #ffffff;
  width: 115px;
  height: 2px;
}
.space-y-29 > *:not(:first-child) {
  margin-top: 29px;
}
.image_13 {
  width: 431px;
  height: 41px;
}
.text_11 {
  color: #000;
  font-size: 28px;

  line-height: 27px;
}
.image_14 {
  width: 750px;
  height: 549.99975px;
}
.section_7 {
  padding: 47px 0 64px 80px;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005960745476580.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.group_13 {
  align-self: center;
  text-align: center;
  padding-top: 25.944px;
}
.text_12 {
  color: #000;
  font-size: 28px;
  font-family: "Albbr";
  line-height: 48px;
}
.text_13 {
  color: #000;
  font-size: 28px;
  font-family: "Albbr";
  line-height: 48px;
}
.text_14 {
  color: #000;
  font-size: 28px;
  font-family: "Albbr";
  line-height: 48px;
}
.text_15 {
  color: #000;
  font-size: 28px;
  font-family: "Albbr";
  line-height: 48px;
}
.button {
  padding-top: 46px;
  margin: 0 auto;
}
.text_16 {
  color: #007aff;
  font-size: 30px;
  border-bottom: 1px solid #007bff;
  padding-bottom: 10px;
  line-height: 29px;
  text-align: center;
}
.section_8 {
  margin-left: 238px;
  background-color: #007bff;
  width: 115px;
  height: 2px;
}
.group_14 {
  margin-top: 221.5px;
}
.group_15 {
  align-self: flex-start;
}
.text_17 {
  color: #000;
  font-size: 58px;
  font-weight: 700;
  line-height: 75px;
}
.text_18 {
  color: #000;
  font-size: 28px;

  line-height: 66px;
}
.text_19 {
  color: #000;
  font-size: 28px;

  line-height: 40px;
}
.image_15 {
  margin-top: 122px;
  width: 145px;
  height: 145px;
}
.image_16 {
  width: 420px;
  height: 456px;
}
.image_17 {
  margin: 30px 30px 0;
  width: 690px;
  height: 468px;
}
.space-y-49 > *:not(:first-child) {
  margin-top: 49px;
}
.section_9 {
  margin: 0 30px;
  padding: 58px 50px 72px;
  background-color: #eaeaea;
}
.text_20 {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  line-height: 34px;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
  width: 100%;
}
.text_21 {
  margin-top: 23px;
  color: #000;
  font-size: 28px;
  width: 100%;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
  line-height: 27px;
}
.text_22 {
  margin-top: 38px;
  color: #747474;
  font-size: 24px;

  line-height: 18px;
}
.group_19 {
  margin-top: 4px;
}
.image_18 {
  margin: 7px 0 9px;
}
.image_20 {
  margin: 7px 0 9px 36px;
}
</style>
